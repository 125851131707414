import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';

import Select from 'react-select';
import { URL_LIST } from '../../../../../constants/apiList';
import { getBrand } from '../../../../../helpers';

import axios from 'axios';
import { Modal } from 'react-bootstrap';

import { toast } from 'react-toastify';


const RescheduleModal = ({role, userId, category, onSubmit, show, closeModal }) => {
  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(category || null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [dateOptions, setDateOptions] = useState([]);
  const [timeOptions, setTimeOptions] = useState({});
  const [rescheduleLoader, setRescheduleLoader] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState(null);
  const fetchCategories = () => {
    const brand = getBrand();
    setRescheduleLoader(true);
    axios.get(`${URL_LIST.GET_CATEGORY}/${role}`, {headers : {brand}})
    .then(
      res => {
        const categoryOptions = res?.data?.data?.stepsConfig?.category?.options;  
        setCategoryOptions(categoryOptions);  
        setRescheduleLoader(false);    
    })
    .catch(err => {
      setRescheduleLoader(false);
      toast.error("Unable to get category data");
      console.error("Error in getting category", err);
    });
  }
  const fetchLanguages = (category) => {
    const brand = getBrand();
    setRescheduleLoader(true);
    axios.get(`${URL_LIST.GET_LANGUAGE}/?category=${category}&role=${role}`, {headers : {brand}})
      .then(res => {
        const languageList = res?.data;
        setLanguageOptions(languageList);
        setRescheduleLoader(false);
      })
      .catch(err => {
        setRescheduleLoader(false);
        toast.error("Unable to get language data");
        console.error("Error in getting language", err);
      });
  };

  const fetchSlots = (category, language) => {
    setRescheduleLoader(true);
    const brand = getBrand();
    axios.post(`${URL_LIST.GET_SLOTS}`, { category, language, role, keyToSend: "role" }, {headers : {brand}})
      .then(res => {
        const { dateOptions, timeOptions } = res?.data;
        setDateOptions(dateOptions);
        setTimeOptions(timeOptions);
        setRescheduleLoader(false);
      })
      .catch(err => {
        setRescheduleLoader(false);
        toast.error("Unable to get slots");
        console.error("Error in getting slots", err);
      });
  };

  const handleBookSlot = () => {
    if (!selectedDate || !selectedTime) {
      toast.error("Please select both date and time");
      return;
    }
    const brand = getBrand();
    const requestData = {
      categories: selectedCategory.value,
      language: selectedLanguage.value,
      role,
      keyToSend: "role",
      slot: selectedTime.value,
      date: selectedDate.value,
      userid : userId
    };
  
    setRescheduleLoader(true);
  
    axios.post(`${URL_LIST.BOOK_SLOT}`, requestData, {headers : {brand}})
      .then(res => {
        toast.success("Slot booked successfully");
        onSubmit();
        closeModal(); 
      })
      .catch(err => {
        setRescheduleLoader(false);
        toast.error("Unable to book slot");
        console.error("Error in booking slot", err);
      })
      .finally(() => {
        setRescheduleLoader(false);
      });
  };

  const handleCategorySelect = (selectedOption) => {
    if (category) {
      toast.error("Cannot Update Category");
      return;
    }
    setSelectedCategory(selectedOption);
    fetchLanguages(selectedOption.value);
    setSelectedLanguage(null);
    setLanguageOptions(null);
    setSelectedDate(null);
    setSelectedTime(null);
    setDateOptions([]);
    setTimeOptions({});
  };

  const handleLanguageSelect = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    fetchSlots(selectedCategory.value, selectedOption.value);
    setSelectedDate(null);
    setSelectedTime(null);
    setDateOptions([]);
    setTimeOptions({});
    };

  const handleDateSelect = (selectedOption) => {
    setSelectedDate(selectedOption);
  };

  const handleTimeSelect = (selectedOption) => {
    setSelectedTime(selectedOption);
  };


  useEffect(() => {
    if (!category){
      fetchCategories();
    }
    else {
      fetchLanguages(category.value)
    }
  }, [role]);

  return (
    <div className={styles.modal}>
       <Modal show={show} onHide={closeModal}>
                  <Modal.Header >
                      <Modal.Title>Reschedule Appointment</Modal.Title>
                  </Modal.Header>
                    <Modal.Body>
                      {rescheduleLoader && <div>Loading...</div>}
        <div className={styles.field}>Select Category</div>
        <div className={styles.row}>
          <Select
            className="regular-select"
            value={selectedCategory}
            options={categoryOptions}
            onChange={handleCategorySelect}
            style={{ width: '200px' }}
            disabled={true}
          />
        </div>
        {selectedCategory && (
          <>
            <div className={styles.field}>Select Language</div>
            <div className={styles.row}>
              <Select
                className="regular-select"
                value={selectedLanguage}
                options={languageOptions}
                onChange={handleLanguageSelect}
                style={{ width: '200px' }}
              />
            </div>
          </>
        )}
    {selectedLanguage && (
      <>
        <div className={styles.field}>Select Date</div>
        <div className={styles.row}>
          <Select
            className="regular-select"
            value={selectedDate}
            options={dateOptions}
            onChange={handleDateSelect}
            style={{ width: '200px' }}
          />
        </div>

        {selectedDate && (
          <>
            <div className={styles.field}>Select Time</div>
            <div className={styles.row}>
              <Select
                className="regular-select"
                value={selectedTime}
                options={timeOptions[selectedDate.value] || []}
                onChange={handleTimeSelect}
                style={{ width: '200px' }}
              />
            </div>
          </>
        )}
      </>
    )}
      </Modal.Body>
        <Modal.Footer>
        <div className="d-flex gap-2">
          {selectedDate && selectedTime && (
            <button 
              className="btn btn-primary" 
              onClick={handleBookSlot}
              disabled={rescheduleLoader}
            >
              {rescheduleLoader ? 'Booking...' : 'Book Slot'}
            </button>
          )}
          <button className="btn btn-secondary" onClick={closeModal}>
            Close
          </button>
        </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RescheduleModal;