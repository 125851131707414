import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const SoScheduledSummaryDetials = ({ currentDetails, show, closeModal }) => {
    return (
      <div>
        <Modal show={show} onHide={closeModal}>
                  <Modal.Header >
                      <Modal.Title>Summary detail</Modal.Title>
                  </Modal.Header>
                    <Modal.Body>
                        <p><strong>Start Time:</strong> {currentDetails?.startTime}</p>
                        <p><strong>Categories:</strong> {currentDetails?.categories ? currentDetails.categories.map(item => item.label).join(', ') : '-'}</p>
                        <p><strong>Language:</strong> {currentDetails?.language || '-'}</p>
                        <p><strong>Mode:</strong> {currentDetails?.mode || '-'}</p>
                        <p><strong>OrderIds:</strong> {currentDetails?.orderIds ? currentDetails.orderIds.join(', ') : '-'}</p>
                    </Modal.Body>
                  <Modal.Footer>
                      <Button variant="secondary" onClick={closeModal}>Close</Button>
                  </Modal.Footer>
              </Modal>
      </div>
    );
  };

  export default SoScheduledSummaryDetials;
